<template>
  <div>
    <v-dialog v-model="value" persistent max-width="400px">
      <v-card>
        <v-card-title> Otimizador de audio</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-file-input
                :disabled="!configCode"
                show-size
                outlined
                label="Selecione um audio para otimizar"
                clearable
                accept=".mp3"
                @change="addAudioWS"
                v-model="file"
              ></v-file-input
            ></v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                prepend-icon="mdi-format-list-bulleted-square"
                :items="formats"
                label="Selecione o formato para otimizar"
                outlined
                v-model="configCode"
                item-value="code"
                item-text="name"
                :disabled="disableFormat"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close"> Cancelar </v-btn>
          <!-- <v-btn color="warning darken-1" text @click="start"> Iniciar </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FileUtil from "@/Utils/File";
export default {
  name: "DialogAudioConverter",
  props: ["value", "target"],
  data: () => ({
    file: null,
    configCode: "",
    formats: [],
    disableFormat: false,
  }),
  methods: {
    close() {
      this.$emit("input", false);
      this.file = null;
    },
    addAudioWS(file) {
      // valida se tem arquivo
      if (file == null) {
        return;
      }
      // valida se o arquivo é maior que 1MB
      // if (file.size > 1000000) {
      //   this.$store.dispatch("resetSnack");
      //   this.$store.dispatch(
      //     "showErrorSnack",
      //     "Arquivo muito grande, tamanho máximo 1MB"
      //   );
      //   return;
      // }
      // monta o form data
      let formData = new FormData();
      formData.append("file", file, file.name);
      formData.append("configCode", this.configCode);
      // envia o arquivo para o servidor, e recebe o arquivo convertido
      this.$http({
        responseType: "arraybuffer",
        data: formData,
        method: "POST",
        url: "audioConverter",
      }).then((resp) => {
        if (resp.status < 399) {
          this.$store.dispatch("resetSnack");
          this.$store.dispatch(
            "showSuccessSnack",
            "Audio convertido com sucesso"
          );
          // pega o nome do arquivo
          let newFilename = resp.headers["content-disposition"].split(
            "filename="
          )[1];
          FileUtil.downloadFile(new Blob([resp.data]), newFilename);
          this.close();
        }
      });
    },
    getFormats() {
      this.$http({
        method: "GET",
        url: "audioConverter",
      }).then((resp) => {
        if (resp.status < 399) {
          this.formats = resp.data;
        }
      });
    },
  },
  mounted() {
    this.getFormats();
    if(this.target){
      this.configCode = this.target;
      this.disableFormat = true;
    }
  },
};
</script>

<style scoped>
</style>